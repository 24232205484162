:root {
  --primary-red: #e02f2f;
  font-family: sans-serif;
  font-size: 16px;
  font-size: calc(16px + 2 * ((100vw - 320px) / 448));
  line-height: 1.5;
  color: #3b3a3a;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

/*Toast Notificaiton Customizations Start*/
.Toastify__toast {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding-left: 20px;
  border-radius: 7px;
}
.Toastify__progress-bar {
  bottom: -1px;
  height: 6px;
}

.Toastify__toast--info {
  background-color: #cce5ff;
  color: #004085;
}
.Toastify__toast--success {
  background-color: #d4edda;
  color: #155724;
}
.Toastify__close-button--success {
  color: black;
}
.Toastify__close-button--info {
  color: black;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    border-radius: 0px;
  }
}
/*Toast Notificaiton Customizations End*/

body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

/* homepage.css starts */
.home-container {
  display: grid;
  grid-template-rows: min-content min-content min-content 1fr;
  padding-top: 7.9vh;
  min-height: 100vh;
}
.home-nav {
  grid-area: 2 / 1 / 3 / 2;
  display: flex;
  flex-direction: column;
  padding: 28px 0 0 12.53vw;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.125rem;
}

.home-btn {
  border-radius: 18px;
  padding: 4px 8.27vw;
  text-align: center;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 18vw;
  cursor: pointer;
}

.login-btn {
  border: solid 1px #656565;
  order: 2;
  background: #ffffff;
}

.signup-btn {
  background: var(--primary-red);
  border: solid 1px var(--primary-red);
  color: white;
  margin-bottom: 12.5px;
}

.home-content {
  grid-area: 1 / 1 / 2 / 2;
  padding-left: 12.53vw;
}

.home-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 3rem;
  line-height: 3rem;
  color: var(--primary-red);
  margin: 0;
}

.home-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.313rem;
  line-height: 1.6rem;
  letter-spacing: -0.1rem;
  color: #858585;
  margin: 0;
}

.home-tagline {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1.688rem;
  line-height: 1.688rem;
  letter-spacing: -0.1rem;
  color: #1d1d1d;
  padding: 7px 0 20px 0;
  margin: 0;
  width: 75vw;
}
.instructions {
  list-style-type: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  color: #1d1d1d;
  padding-left: 2vw;
  margin: 0;
  width: 68vw;
}

.instructions li {
  position: relative;
  margin: 0;
  padding-bottom: 1em;
  padding-left: 20px;
}

.instructions li:before {
  background-color: #707070;
  width: 2px;
  content: '';
  position: absolute;
  top: 12px;
  bottom: 0px;
  left: 4px;
}

.instructions li:last-child:before {
  height: 0px;
}

.bullet.big {
  width: 14px;
  margin-left: -22px;
  padding-right: 8px;
  display: inline-block;
}
.home-image {
  width: 100vw;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.home-image img {
  width: 121vw;
  height: 90.9vw;
  position: relative;
  left: -2vw;
}
/* homepage.css ends */
/*Footer*/
footer {
  display: grid;
  justify-items: center;
  font-family: 'Montserrat', sans-serif;
  background-color: #e4e4e4;
  padding: 33px 0; /**/
  /*Extra*/
  width: 100%;
  margin-top: 10vh;
  align-self: end;
}

.footer-social {
  display: flex;
  justify-content: space-between;
  width: 65vw;
  max-width: 350px; /**/
  padding-bottom: 33px; /**/
}

.ft-img {
  width: 42px; /**/
  height: 42px; /**/
  transition: 0.5s;
}

.ft-img:hover {
  opacity: 0.65;
  transform: scale(1.08);
}

.ft-discord {
  width: 48px; /**/
  height: 48px; /**/
}

.footer-t {
  display: none;
}

.footer-cr {
  text-align: center;
  font-size: 0.625rem;
  width: 90vw;
}
/*Footer Ends*/
/* signup.css starts */
.signup-container {
  display: grid;
  grid-template-rows: 20vh 80vh;
}

.ub-topbar {
  grid-area: 1/1/2/2;
}

.ub-logo {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 3.313rem;
  line-height: 3.313rem;
  color: var(--primary-red);
  margin: 25px 0 0 6.67vw;
}

.ub-logo-tag {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 0.813rem;
  line-height: 0.813rem;
  letter-spacing: -0.04rem;
  color: #858585;
  margin: 0 0 0 6.67vw;
}

.login-form {
  grid-area: 2/1/3/2;
  margin: 0 6.67vw;
  font-family: 'Montserrat', sans-serif;
}

.form-heading {
  font-weight: 700;
  font-size: 2.063rem;
  line-height: 2.063rem;
  letter-spacing: -0.1rem;
  margin: 0 0 15px 0; /**/
}
.form-label {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.063rem;
  letter-spacing: -0.1rem;
  margin: 0;
}
.form-input {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 0.813rem;
  color: #9196a5;
  width: 86.67vw;
  height: 44.5px; /**/
  border: solid 1px #a6a6a6;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 5px 2.67vw;
}

.submit-btn {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 1.063rem;
  padding: 11px 2.67vw;
  border: none;
  border-radius: 5px;
  text-align: center;
  width: 86.67vw;
  cursor: pointer;
  background: var(--primary-red);
  color: white;
  margin: 15px 0 0 0; /**/
}

.switch-form {
  text-align: center;
  font-weight: 400;
  font-size: 1.063rem;
  margin: 13px 0 0 0; /**/
}

.ub-image {
  display: none;
}

.frgt-pswrd {
  text-align: right;
  margin-bottom: 7px;
  font-size: 0.813rem;
}

.frgt-pswrd-exp {
  margin-bottom: 5px;
}
/* signup.css ends */

/* userreclist.css starts */
.user-rec-list {
  display: grid;
  font-family: 'Montserrat', sans-serif;
  min-height: 120vh;
}

.r-nav {
  display: flex;
  padding: 21px 0 57px 0; /**/
}

.nav-logo-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 3.313rem;
  line-height: 3.313rem;
  color: var(--primary-red);
  margin: 0 0 0 5.6vw;
}

.nav-logo-username {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: #656565;
  margin: 0 0 0 5.6vw;
}

.rec-case {
  display: grid;
}
.rec-showcase {
  display: grid;
  justify-items: center;
}

.rs-poster {
  border-radius: 14px;
  width: 70.67vw;
  max-width: 35vh;
  height: auto;
  box-shadow: 1px 3px 99px rgba(0, 0, 0, 0.2);
  border: none;
  margin-bottom: 13px; /**/
}

.rs-title {
  font-weight: 700;
  font-size: 1.875rem;
  width: 70.67vw;
  max-width: 35vh;
  line-height: 1.9rem;
  text-align: center;
  margin-bottom: 10px;
}

.rs-details {
  width: 70.67vw;
  max-width: 35vh;
  font-weight: 200;
  font-size: 1rem;
  text-align: center;
  line-height: 1.3rem;
  margin-bottom: 22.5px; /**/
}

.rs-like {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: 200;
  font-size: 1.625rem;
  line-height: 1.625rem;
  color: var(--primary-red);
  cursor: pointer;
}

.rs-like-icon {
  width: 10vw;
}

.rec-list {
  padding: 0 6.13vw;
  margin: 50px 0; /**/
}

.rl-heading {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 2.375rem;
  line-height: 2.375rem;
  letter-spacing: -0.1rem;
  color: var(--primary-red);
  display: inline;
}
.pin-list {
  box-sizing: border-box;
  cursor: pointer;
  margin-left: 2vw;
}

.pin-list img {
  object-fit: contain;
  height: 1.4rem;
  width: 1.4rem;
}

.rl-subheading {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.25rem;
  width: 60vw;
  margin-bottom: 27px; /**/
}

.rl-grid {
  display: grid;
  grid-row-gap: 17px; /**/
}

.rl-entry {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: auto;
  background: #e4e4e4;
  border-radius: 11px;
}

.rl-poster {
  border-radius: 5px;
  width: 20vw;
  height: auto;
  align-self: center;
}

.rl-greybox {
  margin: 0 0 0 3.73vw; /**/
  align-self: center;
}
.rl-title {
  box-sizing: border-box;
  font-size: 1.375rem;
  font-weight: 700;
  width: 45vw;
  line-height: 1.375rem;
  /*text clamping*/
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.rl-details {
  box-sizing: border-box;
  margin-top: 9px; /**/
  font-size: 0.8rem;
  width: 45vw;
  /*text clamping*/
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.rl-utility {
  display: grid;
  width: 18vw;
}

.rl-like {
  display: grid;
  justify-self: center;
  align-self: center;
  cursor: pointer;
}

.rl-like-icon {
  width: 10vw;
  height: auto;
}

.rl-like-count {
  font-weight: 200;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-top: 2px; /**/
  text-align: center;
  color: var(--primary-red);
}

.search-title {
  font-size: 1.938rem;
  font-weight: 700;
  line-height: 1.938rem;
  justify-self: start;
  width: 90vw;
  padding-left: 7.73vw;
}
.search-title sup {
  font-size: 3.063rem;
  font-weight: 900;
  color: var(--primary-red);
}
.search-subtitle {
  font-size: 1.25rem;
  line-height: 1.25rem;
  width: 80vw;
  padding-left: 7.73vw;
  justify-self: start;
}
.search-form {
  padding: 13px 0 0 0; /**/
  justify-self: center;
  display: grid;
  grid-template-columns: min-content min-content;
  grid-template-rows: auto;
}

.search-form input {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  font-weight: 400;
  color: #9196a5;
  border-radius: 5px 0 0 5px;
  border: solid 1px #a6a6a6;
  padding: 5px 2.67vw;
  font-size: 1rem;
  width: 70.67vw;
  height: 45px; /**/
  min-height: 5.54vh;
}

.search-btn {
  width: 16.27vw;
  background: black;
  height: 45px; /**/
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 5.54vh;
}

.search-btn img {
  width: 6.592vw;
  height: 6.592vw;
  max-height: 75%;
  max-width: 75%;
}

.rmv-entry {
  width: 6.93vw;
  height: 6.93vw;
  justify-self: end;
  padding: 3.17vw;
  cursor: pointer;
}

.dash-like {
  display: flex;
  align-items: center;
  align-self: center;
  justify-self: end;
  padding: 0 3.17vw 0 0;
}

.dash-like-icon {
  width: 7vw;
  height: 7vw;
}
.dash-like-count {
  font-size: 1.188rem;
  color: var(--primary-red);
  align-self: center;
}

.add-entry-msg {
  color: #707070;
  text-align: center;
  align-self: center;
  padding: 0;
}

.ae-msg-main {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 700;
}

.ae-msg-sub {
  font-size: 0.813rem;
  line-height: 0.813rem;
}

.rl-poster-placeholder {
  width: 37.5px;
  height: 113px;
}

.search-res-grid {
  margin-top: 30px; /**/
  display: grid;
  grid-row-gap: 17px; /**/
}

.clear-res {
  font-size: 1rem;
  font-weight: 500;
  background: #e4e4e4;
  text-align: center;
  border-radius: 7px;
  padding: 8px 0; /**/
  cursor: pointer;
}

.search-entry {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: auto;
  background: #2d2d2d;
  border-radius: 11px;
}

.s-greybox {
  margin: 0 0 0 3.73vw; /**/
  align-self: center;
}
.s-title {
  color: #eaeaea;
}
.s-details {
  color: #eaeaea;
}

.s-utility {
  display: grid;
  width: 18vw;
}

.s-add-icon {
  cursor: pointer;
  align-self: center;
  justify-self: center;
  width: 8vw;
  height: 8vw;
}
.no-results {
  text-align: center;
  width: 80vw;
  padding: 10px; /**/
}
.no-results-title {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 700;
  width: 80vw;
}

.no-results-details {
  width: 80vw;
  margin: 0;
}
/* userreclist.css ends*/

/*Spinner Code*/
.orbit-spinner,
.orbit-spinner * {
  box-sizing: border-box;
}
.spinner-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-res-spinner {
  margin: auto;
  padding: 30px;
}

.orbit-spinner {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  perspective: 800px;
}

.orbit-spinner .orbit {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.orbit-spinner .orbit:nth-child(1) {
  left: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-one-animation 1200ms linear infinite;
  border-bottom: 3px solid #ff1d5e;
}

.orbit-spinner .orbit:nth-child(2) {
  right: 0%;
  top: 0%;
  animation: orbit-spinner-orbit-two-animation 1200ms linear infinite;
  border-right: 3px solid #ff1d5e;
}

.orbit-spinner .orbit:nth-child(3) {
  right: 0%;
  bottom: 0%;
  animation: orbit-spinner-orbit-three-animation 1200ms linear infinite;
  border-top: 3px solid #ff1d5e;
}

@keyframes orbit-spinner-orbit-one-animation {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes orbit-spinner-orbit-two-animation {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes orbit-spinner-orbit-three-animation {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
/*Spinner Code Ends*/

/*Email Verification Success or Error Msg*/
.verify-container {
  background: #f7f7f7;
  display: grid;
  grid-template-rows: min-content min-content;
  justify-items: center;
  min-height: 100vh;
  /* height: 100vh; */
}

.verify-topbar {
  text-align: center;
  margin-top: 40px; /**/
}

.verify-logo {
  margin: 0;
}

.verify-msg {
  background: white;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  box-shadow: 0 48px 99px 0 rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  margin-top: 24px; /**/
  display: grid;
  grid-template-rows: repeat(4, min-content);
  grid-template-columns: 83.2vw;
  justify-items: center;
  margin-bottom: 50px; /**/
}

.verify-icon {
  width: 10.13vw;
  height: 10.13vw;
  margin: 45px 0; /**/
}

.verify-heading {
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 1.875rem;
  width: 68vw;
}

.verify-subhead {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.125rem;
  width: 63.5vw;
  margin: 14px 0 0 0; /**/
}

.verify-btn {
  width: 42.133vw;
  margin: 45px 0; /**/
}

.verify-form {
  font-size: 1.55rem;
}
/*Email Verification ends*/
/*Pinned List Starts*/
.plist-heading-container {
  text-align: center;
  margin-bottom: 42px; /**/
}

.plist-subheading {
  margin: auto;
  justify-self: center;
  width: 75vw;
}

.plist-grid {
  display: grid;
  justify-items: center;
  grid-row-gap: 21px;
  margin-bottom: 50px; /**/
}

.plist-entry {
  border-radius: 11px;
  width: 84vw;
  text-align: center;
  display: grid;
  height: 113px; /**/
  position: relative;
  background: white;
  z-index: auto;
}

.plist-entry::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: inherit;
  box-shadow: 0 61px 93px 0 rgba(0, 0, 0, 0.16);
  z-index: -1;
}

.plist-msg:hover {
  color: var(--primary-red);
}

.plist-msg {
  grid-area: 1/1/2/2;
  align-self: center;
  color: #707070;
  cursor: pointer;
  transition: 0.5s;
}

.plist-null {
  background: #e4e4e4;
}

.plist-null::before {
  box-shadow: none;
}

.plist-uname {
  font-size: 1.375rem;
}

.rmv-plist {
  grid-area: 1/1/2/2;
  width: 6.93vw;
  height: 6.93vw;
  max-width: 35px;
  justify-self: end;
  padding: 3.17vw;
  cursor: pointer;
}
/*Pinned List Ends*/

@media only screen and (min-width: 769px) {
  :root {
    font-size: 16px;
    /*Changed 9 to 7 (max: 14px)*/
    font-size: calc(7px + 7 * ((100vw - 769px) / 1151));
  }

  /* homepage.css starts */
  .home-container {
    grid-template-columns: 28.8vw 1fr;
    grid-template-rows: auto auto 1fr;
    padding-top: 0;
  }
  .home-nav {
    grid-area: 1 / 1 / 2 / 3;
    justify-self: end;
    flex-direction: row;
    padding: 4.72vh 3.44vw 0 0; /*Height Issue? 51px*/
    font-size: 1.688rem;
    line-height: 1.688rem;
  }
  .home-btn {
    border-radius: 25px;
    transition: 0.5s;
    padding: 0.78vh 0; /*Height Issue? 8.5px*/
    width: fit-content;
    width: -moz-fit-content;
    min-width: 7.8vw; /*Minor Change from 8.8rem*/
    background-size: 200% auto;
  }
  .login-btn {
    background-image: linear-gradient(to right, #fff 0%, #fff 51%, #ddd 100%);
    order: 1;
    margin-right: 0.6vw;
  }
  .signup-btn {
    margin: 0;
    order: 2;
    background-image: linear-gradient(
      to right,
      var(--primary-red) 0%,
      var(--primary-red) 51%,
      #800000 100%
    );
  }
  .home-btn:hover {
    background-position: right center;
  }

  .home-content {
    grid-area: 2 / 1 /3 / 2;
    padding-left: 9.6vw;
    padding-top: 14vh; /*Height Issue? 159px*/
  }
  .home-title {
    font-size: 5.813rem;
    line-height: 5.813rem;
  }
  .home-subtitle {
    font-size: 2.625rem;
    line-height: 2.625rem;
    width: 28.8vw;
  }

  .home-tagline {
    font-size: 3.063rem;
    line-height: 3.3rem;
    width: 28.8vw;
  }

  .instructions {
    font-size: 1.688rem;
    width: 22vw;
    line-height: 1.6rem;
    padding: 3.8vh 0 0 1vw; /*Height Issue? 41px*/
  }

  .instructions li:before {
    top: 1.56vh; /*Height Issue? 25px 2.31vh*/
    left: -0.5px;
  }

  .bullet.big {
    width: 19px;
    margin-left: -29px;
    padding-right: 8px;
    display: inline-block;
  }

  .home-image {
    grid-area: 2 / 1 / 3 / 3;
    justify-self: end;
    width: auto;
    position: relative;
    top: 8vh; /*Height Issue? 86px*/
  }
  .home-image img {
    width: 57.9vw /*64.84vw*/;
    height: 43.7vw /*48.9vw*/;
    position: relative;
    /* left: 1.56vw; */
  }
  /* homepage.css ends*/
  /*Footer*/
  footer {
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content;
    justify-items: unset;
    padding: 3vh 0 2vh 0; /**/
    /*Extra*/
    grid-column: 1/-1;
    margin-top: 15vh;
  }

  .footer-t {
    display: unset;
    order: -1;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 4.25rem;
    color: #707070;
    padding-left: 3.65vw;
  }

  .footer-social {
    align-self: center;
    justify-self: end;
    width: 10.5vw;
    min-width: 175px; /**/
    padding: 10px 3.62vw 0 0;
  }

  .footer-cr {
    grid-area: 2/1/3/3;
    text-align: center;
    font-size: 1rem;
    width: unset;
  }
  /*Footer Ends*/
  /* signup.css starts */
  .signup-container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 32.5vh 67.5vh;
  }

  .ub-topbar {
    grid-area: 1/1/2/2;
  }

  .ub-logo {
    font-size: 5.063rem;
    line-height: 5.063rem;
    margin: 3.52vh 0 0 2.55vw; /*Height Issue? 38px*/
  }

  .ub-logo-tag {
    font-size: 1.188rem;
    line-height: 1.188rem;
    letter-spacing: -0.03rem;
    margin: 0 0 0 2.55vw;
  }

  .login-form {
    grid-area: 1/2/3/3;
    margin: 0;
    align-self: center;
    justify-self: center;
  }

  .form-heading {
    font-size: 3.188rem;
    line-height: 3.188rem;
    margin: 0 0 1.8vh 0; /*Height Issue? 15px*/
  }
  .form-label {
    font-size: 1.375rem;
    line-height: 1.375rem;
    margin: 0 0 0.37vh 0; /*Height Issue? 4px*/
  }
  .form-input {
    font-size: 1.25rem;
    width: 28vw;
    height: 6.02vh; /*Height Issue? 65px*/
    margin-bottom: 1.94vh; /*Height Issue? 21px*/
    padding: 0.46vh 0.68vw; /*Height Issue? 5px*/
    max-height: 50px;
  }

  .submit-btn {
    font-size: 1.563rem;
    padding: 1.62vh 0; /*Height Issue? 17.5px*/
    width: 28vw;
    transition: 0.5s;
    background-size: 150% auto;
    background-image: linear-gradient(
      to right,
      var(--primary-red) 0%,
      var(--primary-red) 51%,
      #800000 100%
    );
    margin: 3.148vh 0 0 0; /*Height Issue? 34px*/
  }

  .submit-btn:hover {
    background-position: right center;
  }

  .switch-form {
    font-size: 1.375rem;
    margin: 1.2vh 0 0 0; /*Height Issue? 13px*/
  }

  .frgt-pswrd {
    margin-bottom: 0.75vh;
    font-size: 0.9rem;
  }

  .frgt-pswrd-exp {
    margin-bottom: 0.46vh;
  }

  .ub-image {
    display: unset;
    grid-area: 2/1/3/2;
  }

  .ub-image img {
    width: 45.88vw; /*52.76vw;*/
    height: 34.58vw; /*39.79vw;*/
    position: relative;
    left: 3.75vw;
  }

  .account-ub-image img {
    width: 40.67vw;
    height: 32.81vw;
    top: -3vh;
    left: 6vw;
  }

  .find-user-img img {
    width: 50vw;
  }
  /* signup.css ends*/
  /* userreclist.css starts */
  .r-nav {
    padding: 2.8vh 0 0 2.55vw; /*Height Issue?40px*/
  }

  .nav-logo-title {
    font-size: 5.063rem;
    line-height: 5.063rem;
    margin: 0;
  }

  .nav-logo-username {
    font-size: 1.875rem;
    line-height: 1.875rem;
    margin: 0;
  }

  .rec-case {
    grid-template-columns: auto auto;
    grid-template-rows: 1fr;
    width: 82.1875vw;
    padding: 8.61vh 0; /*Height Issue?93px*/
    justify-self: center;
  }
  .rec-showcase {
    justify-items: start;
    grid-template-rows: repeat(4, min-content);
  }

  .rs-poster {
    width: 20.94vw; /*20.94vw*/
    max-width: 33vh; /*20.94vw*/
    margin-bottom: 1.94vh; /*Height Issue?21px*/
  }

  .rs-title {
    font-size: 2.625rem; /*Minor Change from 3.625rem*/
    line-height: 2.625rem; /*Minor Change from 3.625rem*/
    margin-bottom: 1vh;
    max-width: 33vh;
    width: 20.94vw;
    text-align: unset;
  }

  .rs-details {
    width: 20.94vw;
    max-width: 33vh;
    font-size: 1.4rem; /*Minor Change from 1.5rem*/
    line-height: 1.9rem;
    text-align: left;
    margin-bottom: 0.74vh; /*Height Issue?22.5px*/
  }

  .rs-like {
    justify-content: start;
    font-size: 2.8rem; /*Minor Change from 3.188rem*/
    line-height: 5rem;
  }

  .rs-like-icon {
    width: auto;
    height: 3.5rem; /*Minor Change from 4rem*/
  }

  .rec-list {
    padding: 0; /**/
    margin: 0; /**/
  }

  .rl-heading {
    font-size: 3.75rem;
    line-height: 3.75rem;
  }

  .pin-list {
    margin-left: 0.52vw;
  }

  .pin-list img {
    height: 2.2rem;
    width: 2.2rem;
  }

  .rl-subheading {
    font-size: 1.938rem;
    line-height: 1.938rem;
    width: unset;
    margin-bottom: 0; /*Height Issue?27px*/
  }

  .rl-grid {
    grid-template-columns: auto auto;
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 1.94vh; /*Height Issue?21px*/
    grid-column-gap: 3.6vw;
    margin-top: 3.61vh; /*Height Issue?39px*/
  }

  .rl-entry {
    border-radius: 5px;
  }

  .rl-poster {
    width: 4.74vw;
    max-width: 8.5vh; /*Limits the width*/
  }

  .rl-greybox {
    margin: 0 0 0 1.25vw; /**/
    width: 12.55vw;
  }
  .rl-title {
    font-size: 1.7rem; /*Minor Change from 1.625rem*/
    width: 12.55vw;
    line-height: 1.8rem; /*Minor Change from 1.625rem*/
  }

  .rl-details {
    margin-top: 0.6vh; /*Height Issue?3px*/
    font-size: 1.05rem; /*Minor Change from 1rem*/
    line-height: 1.3rem; /*Minor Change from 1.2rem*/
    width: 12.55vw;
  }
  .rl-utility {
    justify-self: end;
    width: fit-content;
    width: -moz-fit-content;
  }

  .rl-like {
    padding-right: 1vw;
  }

  .rl-like-icon {
    width: 2vw;
    justify-self: center;
    height: auto;
  }

  .rl-like-count {
    font-size: 1.563rem;
    line-height: 1.563rem;
    margin-top: 2px; /**/
  }

  .search-title {
    font-size: 3.188rem;
    line-height: 3.188rem;
    width: unset;
    padding-left: 0;
  }
  .search-title sup {
    font-size: 4.688rem;
  }
  .search-subtitle {
    font-size: 1.938rem;
    line-height: 1.938rem;
    width: 21.72vw;
    padding-left: 0;
  }
  .search-form {
    padding: 2.78vh 0 0 0; /*Height Issue?30px*/
    justify-self: start;
  }

  .search-form input {
    padding: 0.46vh 0.68vw; /*Height Issue? 5px*/
    font-size: 1.625rem;
    width: 17vw; /*18.85vw;*/
    height: 6.39vh; /*Height Issue?69px*/
    max-height: 60px;
    min-height: unset;
  }

  .search-form input:focus {
    outline: none;
    border: solid 1.5px black;
    border-right: none;
  }
  .search-btn {
    width: 4.9vw;
    height: 6.39vh; /*Height Issue?69px*/
    max-height: 60px;
    min-height: unset;
    transition: 0.5s;
    background-size: 200% auto;
    background-image: linear-gradient(
      to right,
      black 0%,
      black 51%,
      var(--primary-red) 100%
    );
  }

  .search-btn:hover {
    background-position: right center;
  }

  .search-btn img {
    width: 1.97vw;
    height: 1.97vw;
  }

  .rmv-entry {
    width: 1.62vw;
    height: 1.62vw;
    padding: 0.78vw;
  }

  .dash-like {
    padding: 0 0.78vw 0 0;
  }

  .dash-like-icon {
    width: 1.56vw;
    height: 1.56vw;
  }
  .dash-like-count {
    font-size: 1.688rem;
  }
  .ae-msg-main {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }

  .ae-msg-sub {
    font-size: 1rem;
    line-height: 1rem;
  }

  .rl-poster-placeholder {
    width: 3vw;
    max-width: 8.5vh;
    height: 6vw;
  }

  .search-res-grid {
    margin-top: 4vh; /*Height Issue? 46px*/
    width: 21.72vw;
  }

  .clear-res {
    font-size: 1.625rem;
    padding: 1vh 0; /*Height Issue? 14px*/
    width: 21.72vw;
  }

  .search-entry {
    border-radius: 7px;
    width: 21.72vw;
    grid-template-columns: min-content min-content 1fr;
  }

  .s-greybox {
    margin: 0 0 0 1.25vw; /**/
    width: 11vw;
    padding-right: 1vw;
  }

  .s-title {
    font-size: 1.4rem;
    width: 11vw;
  }
  .s-details {
    font-size: 1rem; /*Minor Change from 0.8rem*/
    width: 11vw;
  }

  .s-utility {
    justify-self: center;
    width: fit-content;
    width: -moz-fit-content;
  }

  .s-add-icon {
    width: 1.6vw;
    height: 1.6vw;
  }

  .no-results {
    width: 17vw;
    padding: 1.4vh; /*Height Issue? 10px*/
  }
  .no-results-title {
    font-size: 1.3rem;
    line-height: 1.5rem;
    width: 17vw;
  }

  .no-results-details {
    width: 17vw;
  }
  /* userreclist.css ends*/

  /*Spinner CSS*/
  .search-res-spinner {
    margin: 0;
    padding: 3.69vh calc(10.86vw - 32.5px);
  }

  /*Email Verification Success or Error Msg*/
  .verify-topbar {
    margin-top: 6.3vh; /**/
  }

  .verify-msg {
    margin-top: 3.33vh; /**/
    grid-template-columns: 31.56vw;
  }

  .verify-icon {
    width: 3.66vw;
    height: 3.66vw;
    margin: 6vh 0; /**/
  }

  .verify-heading {
    font-size: 2.43rem;
    line-height: 2.43rem;
    width: 24vw;
  }

  .verify-subhead {
    font-size: 1.5rem;
    line-height: 1.5rem;
    width: 22vw;
  }

  .verify-btn {
    width: 10.67vw;
    margin: 10vh 0; /**/
    font-size: 1.313rem;
    padding: 1vh 0;
  }

  .verify-form {
    font-size: 2.5rem;
  }
  /*Email Verification ends*/
  /*Pinned List Starts*/
  .pinnedlist-container {
    margin-top: 8vh;
  }
  .plist-heading-container {
    margin-bottom: 6.2vh; /**/
  }

  .plist-grid {
    /* grid-row-gap: 37px; */
    margin-bottom: 4.6vh; /**/
    /* grid-template-columns: 1fr 1fr 1fr; */
    width: 68vw;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .plist-entry {
    width: 20.7vw;
    min-height: 115px;
    height: 12.5vh; /*Issue with height???135px*/
    margin-bottom: 3.43vh; /**/
  }

  .plist-uname {
    font-size: 1.75rem;
    line-height: 2.2rem;
  }

  .rmv-plist {
    width: 1.62vw;
    height: 1.62vw;
    padding: 0.78vw;
  }
  /*Pinned List Ends*/
}
