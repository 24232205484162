.menuIcon {
  width: 35px;
  height: 35px;
  margin-right: 4vw;
}

.menu-item {
  display: flex !important;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  line-height: 1.4rem;
  color: white;
  cursor: pointer;
  padding: 20px 0;
}

.menu-sub-item {
  padding: 10px 0 10px calc(50px + 4vw);
  font-size: 1rem;
}

.msi-one {
  padding-top: 0;
}

.msi-two {
  padding-bottom: 20px;
}

.menu-item:hover {
  color: var(--primary-red);
}

.menu-item:focus {
  outline: none;
}

.cta {
  padding: 10px 0;
}

.menu-item-cta {
  border-radius: 18px;
  padding: 6px 8.27vw;
  text-align: center;
  font-size: 1.3rem;
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-family: 'Source Sans Pro', sans-serif;
  width: 40%;
}

.login-cta {
  background: #ffffff;
  color: #656565;
}
.signup-cta {
  background: var(--primary-red);
  color: white;
}

.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 25px;
  left: calc(96vw - 36px);
  top: 40px;
}

.bm-burger-button button:focus {
  outline: none;
}

.bm-burger-bars {
  background: #373a47;
  border-radius: 7px;
}

.bm-burger-bars-hover {
  background: #a90000;
}

.bm-cross-button {
  height: 20px !important;
  width: 20px !important;
  right: 20px !important;
  top: 20px !important;
}

.bm-cross-button button:focus {
  outline: none;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

.bm-menu {
  background: #2d2d2d;
  padding: 3em 1.5em 0;
  font-size: 1.15em;
}

.bm-item {
  display: inline-block;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 769px) {
  .menuIcon {
    width: 1.8vw;
    height: 1.8vw;
    margin-right: 1vw;
  }

  .menu-item {
    line-height: 1.8rem;
    padding: 1.85vh 0;
  }

  .menu-sub-item {
    padding: 0.925vh 0 0.925vh 3.6vw;
    font-size: 1.4rem;
  }

  .msi-one {
    padding-top: 0;
  }

  .msi-two {
    padding-bottom: 1.85vh;
  }

  .bm-menu {
    background: #2d2d2d;
    padding: 3em 1.5em 0;
    font-size: 1.55em;
  }

  .cta {
    padding: 1.5vh 0;
  }
  .menu-item-cta {
    border-radius: 25px;
    padding: 1vh 2.5vw;
    font-size: 1.688rem;
    width: 30%;
    background-size: 200% auto;
    transition: 0.5s;
  }
  .menu-item-cta:hover {
    background-position: right center;
  }
  .login-cta {
    background-image: linear-gradient(to right, #fff 0%, #fff 51%, #ccc 100%);
  }
  .signup-cta {
    background-image: linear-gradient(
      to right,
      var(--primary-red) 0%,
      var(--primary-red) 51%,
      #800000 100%
    );
  }
}
